import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';

const WorkContainer = styled.div`
  &:hover > .company-logo {
    opacity:1;
  }
  &:nth-of-type(1) {
    margin-top:10px;
  }
  margin-top:20px;
`
const WorkCompany = styled.h3``
const WorkInitialRole = styled.h4``
const WorkLink = styled.a``
const WorkTitle = styled.p``
const WorkYears = styled.em``
const WorkDescription = styled.p``

const WorkLogo = styled.img`
  position:relative;
  float:right;
  opacity:0.3
`

const Resume = ({data}) => {
  console.log(data)
  const [skillmessage, setSkillMessage] = useState("");
  const [education, setEducation] = useState("");
  const [work, setWork] = useState(""); 
  const [skills,setSkills] = useState("");
  const [skillcloud, setSkillCloud] = useState("")

  useEffect(() => {
    if(data){
      setSkillMessage(data.skillmessage)
      setEducation(data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      }))
      setWork(data.work.map(function(work){
        return (
        <WorkContainer key={work.company} className={work.promotion ? 'promotion' : ''}>
          { work.logo && <WorkLogo className="company-logo" src={'/images/resume-logos/'+work.logo}/> }
          {
          work.promotion ? 
            <WorkInitialRole>Initial Role</WorkInitialRole> : 
            <WorkCompany>{work.company}</WorkCompany>
          }
          { work.link && <WorkLink href={work.link}> {work.link}</WorkLink>}
            <WorkTitle className="info">{work.title}<span>&bull;</span> <WorkYears className="date">{work.years}</WorkYears></WorkTitle>
            <WorkDescription dangerouslySetInnerHTML={{__html: work.description}}></WorkDescription>
        </WorkContainer>
        )
      }))
      setSkills(data.skills.map(function(skills){
        let className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
      }))
      setSkillCloud(data.skillcloud.map((clouditem) => {
        let className = 'skill-cloud-item '+clouditem.toLowerCase();
        return <li key={clouditem} className={className}>{clouditem}</li>
      }))
    }
  },[data])
  


    return (
    <section id="resume">

      { education && <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>
      </div>}


    {work && <div className="row work">

         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col" >
          {work}
        </div>
    </div>}



      {skills && <div className="row skill">
         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">

           <h4>
              {skillmessage}
            </h4>
            <ul className="skill-cloud">
              {skillcloud}
            </ul>

          <div className="bars">
            <br />
            <h4>
              Proficiency Breakdown
            </h4>
            <br />
            <ul className="skills">
              {skills}
            </ul>
          </div>
			  </div>
      </div>}
   </section>
   )
};

export default Resume;
