import React from "react";
import styled from "@emotion/styled";

const Container = styled.section`

`

const SectionBase = styled.section`
    width:100vw;
    height:100vh;
    background-size:cover;
    display: flex;
    align-items: center;
    justify-content: center;

    & > h1 {
        flex: 1 1;
        font-size: clamp(30px, 15vw, 9em);
        line-height: 1.5em;
        color: #fff;
        text-align: center;
    }
`

const HeroSection = styled(SectionBase)`
    background: url('/images/vision-board/hero.jpg');
    background-size:cover;
    background-attachment: fixed;
`

const GoalOne = styled(SectionBase)`
    background: url('/images/vision-board/goal-budget.jpg');
    background-size:cover;
    background-position:75% 50%;
`

const GoalTwo = styled(SectionBase)`
    background: url('/images/vision-board/goal-fitness.jpg');
    background-size:cover;
    background-attachment: fixed;
`

const GoalThree = styled(SectionBase)`
    background: url('/images/vision-board/goal-coding.jpg');
    background-size:cover;
`

const GoalFour = styled(SectionBase)`
    background: url('/images/vision-board/goal-money.jpg');
    background-size:cover;
    background-attachment: fixed;
`


const VisionBoard = () => {
    return (
        <Container>
            <HeroSection>
                <h1> Vision Board 2023</h1>
            </HeroSection>
            <GoalOne> 
                <h1>Stay On Budget</h1>
            </GoalOne>
            <GoalTwo>
                <h1>Work Out Consistently</h1>    
            </GoalTwo>
            <GoalThree>
                <h1>Build a Product</h1>
            </GoalThree>
            <GoalFour>
                <h1>Alternate Source of Income</h1>
            </GoalFour>
        </Container>
    )
}


export default VisionBoard;