import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import VisionBoard from '../Components/VisionBoard';

export const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
    },
    {
        path: "explorations",
        element: <div>Explorations</div>,
    },
    {
        path:"/vision-board/2023",
        element: <VisionBoard />
    },
]);