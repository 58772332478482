import React, { useState, useEffect }  from 'react';

const Header = ({data}) => {
   console.log(data)
   const [name, setName] = useState("");
   const [occupation, setOccupation] = useState("");
   const [description, setDescription] = useState("");
   const [city, setCity] = useState("");
   const [networks, setNetworks] = useState("")

   useEffect (()=> {
      if(data){
         setName(data.name);
         setOccupation(data.occupation);
         setDescription(data.description);
         setCity(data.address.city)
         //Social Networks
         setNetworks(data.social.map((network) => {
            if(network.active === 1)
               return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
            return "";
         }))
      }
   },[data])
     
  return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            <li><a className="smoothscroll" href="#about">About</a></li>
	         <li><a className="smoothscroll" href="#resume">Resume</a></li>
            <li><a className="smoothscroll" href="#contact">Contact</a></li>
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">I'm {name}.</h1>
            <h3 className="banner-desc">I'm a <span className="highlighted-text">{city}</span> based <span className="highlighted-text">{occupation}</span>. {description}.</h3>
            <hr />
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   </header>
   );
}

export default Header;
