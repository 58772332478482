import React, {useEffect, useState} from 'react';

const About = ( props ) => {
   const [name, setName] = useState("");
   const [profilepic, setProfilepic] = useState("");
   const [bio, setBio] = useState("");
   const [phone, setPhone] = useState("");
   const [city, setCity] = useState("");
   const [state, setState] = useState("");
   const [zip, setZip] = useState("");
   const [email, setEmail] = useState("");
   const [resumeDownload, setResumeDownload] = useState("");
   

   useEffect(()=> {
      if(props.data){
         const { name, image, bio, phone, city, state, zip, email, resumeDownload} = props.data;

         setName(name);
         setProfilepic(image);
         setBio(bio)
         setCity(city)
         setState(state)
         setZip(zip)
         setEmail(email)
         setPhone(phone)
         setResumeDownload(resumeDownload);

      }
   },[props])
   return (
         <section id="about">
         <div className="row">
            <div className="three columns">
               <img className="profile-pic" src={''+profilepic} alt="Aaron Pedro Profile Pic" />
            </div>
            <div className="nine columns main-col">
               <h2>About Me</h2>

               <p>{bio}</p>
               <div className="row">
                  <div className="columns contact-details">
                     <h2>Contact Details</h2>
                     <p className="address">
                        <span>{name}</span><br />
                        {city && (
                           `<span>
                                 ${city}  <br />
                                 ${state}, ${zip}
                           </span><br />`
                        )}
                        <span>{phone}</span><br />
                        <span>{email}</span>
                     </p>
                  </div>
                  <div className="column centered download">
                     <p>
                        <a href={resumeDownload} className="button" target='_blank' rel="noopener noreferrer" ><i className="fa fa-download"></i>Download Resume</a>
                     </p>
                  </div>
               </div>
            </div>
         </div>

      </section>
   );
}

export default About;
