import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { RouterProvider, } from "react-router-dom";
import { router } from './router/router';

ReactDOM.render( 
  <RouterProvider router={router} />, document.getElementById('root')
);
registerServiceWorker();
